// 百度身份证识别错误码
export const baiduError = {
  "1": "服务器内部错误",
  "2": "服务暂不可用",
  "3": "调用的API不存在",
  "4": "集群超限额",
  "6": "无权限访问该用户数据",
  "14": "IAM鉴权失败",
  "17": "已支持计费的接口",
  "18": "QPS超限额",
  "19": "请求总量超限额",
  "100": "无效的access_token参数",
  "110": "access_token无效",
  "111": "token过期",
  "216100": "请求中包含非法参数",
  "216101": "缺少必须的参数",
  "216102": "请求了不支持的服务",
  "216103": "请求中某些参数过长",
  "216110": "appid不存在",
  "216200": "图片为空",
  "216201": "上传的图片格式错误",
  "216202": "上传的包体积过大",
  "216630": "识别错误",
  "216631": "识别银行卡错误",
  "216633": "识别身份证错误",
  "216634": "检测错误",
  "282000": "服务器内部错误",
  "282003": "请求参数缺失",
  "282005": "处理批量任务时发生部分或全部错误",
  "282006": "批量任务处理数量超出限制",
  "282100": "图片压缩转码错误",
  "282102": "未检测到图片中识别目标",
  "282103": "图片目标识别错误",
  "282110": "URL参数不存在",
  "282111": "URL格式非法",
  "282112": "url下载超时",
  "282113": "URL返回无效参数",
  "282114": "URL长度超过1024字节或为0",
  "282808": "id 不存在",
  "282809": "返回结果请求错误",
  "282810": "图像识别错误"
};
